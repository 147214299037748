export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1920,
  height: 1080,
  frameRate: 26,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';
export const FRONTEND_URL = 'https://devvideo.meistermacher.de/';
export const API_URL = 'https://mmvdev.meistermacher.de/api/';
//export const FRONTEND_URL = 'https://video.meistermacher.de/';
//export const API_URL = 'https://mm.video/api/';
//export const FRONTEND_URL = 'http//localhost:3000/';
//export const API_URL = 'http://saas.local/api/';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';
