import { Room, TwilioError } from "twilio-video";
import { useEffect } from "react";
import axiosInstance from "../../../axiosConfig";
import { Callback } from "../../../types";

export default function useHandleRoomDisconnection(
  room: Room | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          onError(error);
        }
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
        const params = new window.URLSearchParams(window.location.search);
        const token = params.get('t');
        axiosInstance
          .post("generate-composition", { token: token })
          .then((response) => {
            if (response.data.data.is_redirect == "1") {
              window.open(response.data.data.redirect_url, '_blank');
            }
          });
      };

      room.on("disconnected", onDisconnected);
      return () => {
        room.off("disconnected", onDisconnected);
      };
    }
  }, [
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  ]);
}
