import React from 'react';
import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import OpenFullScreen from '../../../icons/OpenFullScreen';
import ExitFullScreen from '../../../icons/ExitFullScreen';
import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Bildschirm teilen';
export const OPEN_FULL_SCREEN_TEXT = 'Vollbild öffnen';
export const EXIT_FULL_SCREEN_TEXT = 'Vollbild beenden';
export const STOP_SCREEN_SHARE_TEXT = 'Bildschirmfreigabe beenden';
export const SHARE_IN_PROGRESS_TEXT = 'Bildschirm kann nicht geteilt werden, wenn ein anderer Benutzer teilt';
export const SHARE_NOT_SUPPORTED_TEXT = 'Bildschirmfreigabe wird von diesem Browser nicht unterstützt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&[disabled]': {
        color: '#bbb',
        '& svg *': {
          fill: '#bbb',
        },
      },
    },
  })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  const [buttonText, setButtonText] = useState(OPEN_FULL_SCREEN_TEXT);
  const [check, setCheck] = useState(false);

  let tooltipMessage = '';

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  function toggleFullScreen() {
    setCheck(!check);
    var elem = document.getElementById('root');

    if (elem && elem.requestFullscreen) {
      setButtonText(EXIT_FULL_SCREEN_TEXT);
      elem.requestFullscreen();
    }

    if (document && document.fullscreenElement) {
      setButtonText(OPEN_FULL_SCREEN_TEXT);
      document.exitFullscreen();
    }
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Button
          className={classes.button}
          onClick={toggleScreenShare}
          disabled={isDisabled}
          startIcon={<ScreenShareIcon />}
          data-cy-share-screen
        >
          {SCREEN_SHARE_TEXT}
        </Button>
        <Button
          className={classes.button}
          onClick={toggleFullScreen}
          disabled={isDisabled}
          startIcon={check ? <ExitFullScreen /> : <OpenFullScreen />}
          data-cy-share-screen
        >
          {buttonText}
        </Button>
      </span>
    </Tooltip>
  );
}
