import { LocalVideoTrack, Room } from 'twilio-video';
import { useState, useEffect, useCallback } from 'react';
import { SELECTED_BACKGROUND_SETTINGS_KEY } from '../../../constants';
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
  isSupported,
} from '@twilio/video-processors';
import panorama_office from '../../../images/panorama_office.jpg';
import panorama_officeThumb from '../../../images/thumb/panorama_office.jpg';
import private_room from '../../../images/private_room.jpg';
import private_roomThumb from '../../../images/thumb/private_room.jpg';
import public_cafe from '../../../images/public_cafe.jpg';
import public_cafeThumb from '../../../images/thumb/public_cafe.jpg';
import { Thumbnail } from '../../BackgroundSelectionDialog/BackgroundThumbnail/BackgroundThumbnail';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axiosConfig';

export interface BackgroundSettings {
  type: Thumbnail;
  index?: number;
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const token = urlParams.get('t');

const imageNames: string[] = ['Panorama Office', 'Private Room', 'Public Cafe'];

const images = [panorama_officeThumb, private_roomThumb, public_cafeThumb];

const rawImagePaths = [panorama_office, private_room, public_cafe];

let currentDomain = '';

axiosInstance.post('get-room-details', { token: token }).then(async response => {
  currentDomain = await response.data.data.domain;

  axiosInstance.get('get-company-background/' + currentDomain).then(response => {
    var allImages = response.data.data;
    if (allImages.length > 0) {
      allImages.forEach((currentImg: any) => {
        let img1 = currentImg;
        if (img1) {
          imageNames.push(img1.name);
          images.push(img1.url);
          rawImagePaths.push(img1.url);
        }
      });
    }
  });
});

let imageElements = new Map();

const getImage = (index: number): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index));
    }
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      imageElements.set(index, img);
      resolve(img);
    };
    img.onerror = reject;
    img.src = rawImagePaths[index];
  });
};

export const backgroundConfig = {
  imageNames,
  images,
};

const virtualBackgroundAssets = '/';
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

export default function useBackgroundSettings(videoTrack: LocalVideoTrack | undefined, room?: Room | null) {
  const { domain } = useParams<{ domain: string }>();
  const [backgroundSettings, setBackgroundSettings] = useState<BackgroundSettings>(() => {
    const localStorageSettings = window.localStorage.getItem(SELECTED_BACKGROUND_SETTINGS_KEY);
    return localStorageSettings ? JSON.parse(localStorageSettings) : { type: 'none', index: 0 };
  });

  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor);
    }
  }, [videoTrack]);

  const addProcessor = useCallback(
    (processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor) => {
      if (!videoTrack || videoTrack.processor === processor) {
        return;
      }
      removeProcessor();
      videoTrack.addProcessor(processor);
    },
    [videoTrack, removeProcessor]
  );

  useEffect(() => {
    if (!isSupported) {
      return;
    }
    // make sure localParticipant has joined room before applying video processors
    // this ensures that the video processors are not applied on the LocalVideoPreview
    const handleProcessorChange = async () => {
      if (!blurProcessor) {
        blurProcessor = new GaussianBlurBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
        });
        await blurProcessor.loadModel();
      }
      if (!virtualBackgroundProcessor) {
        virtualBackgroundProcessor = new VirtualBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          backgroundImage: await getImage(0),
          fitType: ImageFit.Cover,
        });
        await virtualBackgroundProcessor.loadModel();
      }
      if (!room?.localParticipant) {
        return;
      }
      if (backgroundSettings.type === 'blur') {
        addProcessor(blurProcessor);
      } else if (backgroundSettings.type === 'image' && typeof backgroundSettings.index === 'number') {
        virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index);
        addProcessor(virtualBackgroundProcessor);
      } else {
        removeProcessor();
      }
    };
    handleProcessorChange();
    window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(backgroundSettings));
  }, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor]);
  return [backgroundSettings, setBackgroundSettings] as const;
}
