import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import axiosInstance from '../../../axiosConfig';
import { useLocation } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '0.7em',
  },
  marginTop: {
    marginTop: '0.7em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },

  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1em 0 0.5em',
    },
  },
  mobileButton: {
    padding: '0.5em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  isVideoCreated: string;
}

export default function DeviceSelectionScreen({ name, roomName, setStep, isVideoCreated }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const {
    getToken,
    isFetching,
    dateFormat,
    acceptance,
    setIsFetching,
    setAcceptance,
    setError,
    roomStatus,
    setRoomStatus,
  } = useAppState();

  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const [timeText, setTimeText] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);

  const [cancelText, setCancelText] = useState('Teilnahme absagen');
  const [testText, settestTextText] = useState('Verbindung testen');
  const [joinText, setjoinTextText] = useState('Meeting beitreten');
  const [cancelStyle, setCancelStyle] = useState<any>('contained');

  const [acceptText, setAcceptText] = useState('Teilnahme bestätigen');
  const [acceptStyle, setAcceptStyle] = useState<any>('contained');

  const [successButtonStyle, setSuccessButtonStyle] = useState<any>({
    backgroundColor: 'white',
    color: 'green',
    border: '1px solid green',
  });

  const [testButtonStyle, setTestButtonStyleButtonStyle] = useState<any>({
    backgroundColor: '#3498DB',
    color: 'white',
    marginTop: '10px',
    minWidth: '190px',
  });

  const [joinButtonStyle, setJoinButtonStyleButtonStyle] = useState<any>({
    backgroundColor: 'white',
    color: '#3498DB',
    border: '1px solid #3498DB',
  });

  const [cancelTestStyle, setcancelTestStyle] = useState<any>({
    minWidth: '190px',
  });

  const submitRef = useRef<HTMLButtonElement>(null);

  const location = useLocation();

  const calculateTimeLeft = () => {
    let difference = +new Date(dateFormat) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: -241,
      seconds: 0,
    };

    if (difference > 0 || difference >= -14400000) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    let timerComponents =
      timeLeft.days +
      ' Tage ' +
      timeLeft.hours +
      ' Stunden ' +
      timeLeft.minutes +
      ' Minuten ' +
      timeLeft.seconds +
      ' Sekunden verbleibend';

    if (roomStatus == 'completed') {
      setButtonDisable(true);
      setTimeText('Anruf beendet');
    } else {
      if (timeLeft.minutes < -240 && timeLeft.hours >= 0) {
        setTimeText('Anruf beendet');
      } else {
        setTimeText(timerComponents);
      }
    }

    if (roomStatus !== 'completed') {
      if (
        timeLeft.days <= 0 &&
        timeLeft.hours <= 0 &&
        (timeLeft.minutes == 0 || (timeLeft.minutes >= -240 && timeLeft.minutes <= 0))
      ) {
        setButtonDisable(false);
        // if(!buttonDisable){
        //   submitRef.current?.click();
        // }
      } else {
        setButtonDisable(true);
      }
    }
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const confirm = searchParams.get('confirm');

    if (confirm == 'no') {
      handleAcceptance('no');
    } else if (confirm == 'yes') {
      handleAcceptance('yes');
    } else {
      setAcceptance('none');
    }
  }, []);

  useEffect(() => {
    if (acceptance === 'no') {
      setCancelText('Teilnahme abgesagt');
      setCancelStyle('contained');
      setAcceptText('Teilnahme bestätigen');
      setSuccessButtonStyle({
        backgroundColor: 'white',
        color: 'green',
        border: '1px solid green',
      });
    } else if (acceptance === 'yes') {
      setCancelText('Teilnahme absagen');
      setCancelStyle('outlined');
      setAcceptText('Teilnahme bestätigt');
      setSuccessButtonStyle({
        backgroundColor: 'green',
        color: '#fff',
      });
    } else if (acceptance === 'none') {
      setCancelText('Teilnahme absagen');
      setCancelStyle('outlined');
      setAcceptText('Teilnahme bestätigen');
      setSuccessButtonStyle({
        backgroundColor: 'white',
        color: 'green',
        border: '1px solid green',
      });
    }
  }, [acceptance]);

  const handleJoin = () => {
    getToken(name, roomName).then(token => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  const handleAcceptance = (response: string) => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('t');

    setIsFetching(true);
    axiosInstance
      .post('update-acceptance', { token: token, response: response })
      .then(response => {
        setIsFetching(false);
        setAcceptance(response.data.data.has_accepted);
      })
      .catch(err => {
        setIsFetching(false);
        setError(err);
      });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Meeting {roomName}
      </Typography>

      {isVideoCreated && isVideoCreated == 'no' && (
        <Grid container justifyContent="center">
          <Grid item md={12} sm={12} xs={12} className={classes.marginTop}>
            <div className={classes.joinButtons}>
              <Button
                variant={cancelStyle}
                color="secondary"
                onClick={() => handleAcceptance('no')}
                style={acceptance != 'none' ? cancelTestStyle : null}
              >
                {acceptance == 'no' && <CheckCircleOutlineIcon />}
                {cancelText}
              </Button>
              <Button style={successButtonStyle} onClick={() => handleAcceptance('yes')}>
                {acceptance == 'yes' && <CheckCircleOutlineIcon />}
                {acceptText}
              </Button>
            </div>
          </Grid>
        </Grid>
      )}

      <Grid container justifyContent="center">
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          className={classes.marginTop}
          style={{ display: acceptance != 'none' ? 'block' : 'none' }}
        ></Grid>
      </Grid>

      <Grid container justifyContent="center" className={classes.marginTop}>
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>

            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12} className={classes.marginTop}>
          <div className={classes.joinButtons}>
            <Button
              variant="contained"
              color="primary"
              data-cy-join-now
              onClick={handleJoin}
              disabled={disableButtons || buttonDisable}
              ref={submitRef}
            >
              {buttonDisable
                ? timeText
                : isVideoCreated && isVideoCreated == 'yes'
                ? 'Aufnahme starten'
                : 'Meeting beitreten'}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Typography variant="h6" className={classes.marginTop} gutterBottom>
        Verbindung testen:
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        1. Sie sehen Ihr Bild unten im Videofenster? <br />
        2. Wenn Sie sprechen leuchtet das Mikrofon im Videodenster grün?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ja! - dann ist alles richtig eingestellt! Sie können das Fenster schließen. Nein? - dann können Sie im Bereich
        "Einstellungen" Ihre Kamera und Mikrofon-Einstellungen bearbeiten (Support kontaktieren)
      </Typography>
    </>
  );
}
